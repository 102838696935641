@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

*::selection{
	background-color: $yellow;
	color: $white;
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.social{
	@include flex(row,flex-start,center);
	gap: 12px;
	a{
		background-color: $yellow;
		width: 40px;
		border-radius: 40px;
		height: 40px;
		@include flex(column,center,center);
		font-size: 20px;
	}	
	@include tablet-up{
		justify-content: flex-end;
		a{
			font-size: 15px;
			width: 30px;
			height: 30px
		}
	}
}

.split{
	@include flex(column,initial,initial);
	gap: 30px;
	@include tablet-up{
		@include flex(row,space-between,initial);
		gap: 40px;
		.side{
			flex: 1;
		}
	}
}

.menu-trigger{
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 1px transparent;
	border-top-color: $yellow;
	border-bottom-color: $yellow;
	position: relative;
	width: 40px;
	height: 25px;
	transition: all .4s;
	&:before,&::after{
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		top: calc(50% - 1px);
		left: 0;
		position: absolute;
		background-color: $yellow;
		transition: all .4s;
	}
	&.active{
		border-color: transparent;
		&::after{
			transform: rotate(45deg);
		}
		&::before{
			transform: rotate(-45deg);
		}
		z-index: 99999;
		right: 40px;
		top: 40px;
		position: absolute;
	}
	@include tablet-up{
		display: none;
	}
}

.nav{
	list-style: none;
	padding: 0;
	margin: 0;
}

@include tablet-down{
	.desktop{
		display: none;
	}
}
@include tablet-up{
	.mobile{
		display: none;
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

h2{
	font-size: 80px;
	font-weight: 300;
	text-align: center;
	margin: 0;
	text-transform: uppercase;
}

.btn{
	text-transform: uppercase;
	font-size: 18px;
	display: inline-block;
	padding: 20px 80px;
	&.btn-yellow{
		background-color: $yellow;
		color: $white;
		border-radius: 50px;
		@include hover-focus{
			background-color: $yellow-dark;
		}
	}
}

header {
	padding: 20px;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9;
	@include tablet-down{
		.container{
			.split{
				@include flex(row,space-between,center);
				.side{
					.menu{
						@include tablet-down{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100vh;
							z-index: 9999;
							backdrop-filter: blur(10px);
							padding: 120px 40px 40px;
							opacity: 0;
							pointer-events: none;
							transition: all .4s;
							background-color: #3131314d;
							&.active{
								opacity: 1;
								pointer-events: all;
							}
							.social{
								margin-bottom: 40px;
							}
							.nav{
								@include flex(column,initial,initial);
								gap: 40px;
								li{
									a{
										font-size: 40px;
										text-transform: uppercase;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@include tablet-up{
		padding: 30px;
		.container{
			.split{
				.side{
					.menu{
						@include flex(row-reverse,flex-start,center);
						gap: 20px;
						.nav{
							@include flex(row,flex-end,center);
							gap: 20px;
							li{
								a{
									text-transform: uppercase;
									font-size: 16px;
									@include hover-focus{
										color: $yellow;
									}
								}
							}
						}
					}
				}
			}
		}	
	}
}

#instagram-posts {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	div{
		a{
			img{
				width: 100%;
				aspect-ratio: 1/1;
				object-fit: cover;
				transition: all .4s;
			}
			@include hover-focus{
				img{
					opacity: .75;
				}
			}
		}
	}
	@include tablet-up{
		display: grid;
		grid-template-columns: repeat(
			auto-fit,
			minmax(
				20%,
				1fr
			)
		);
	}
}

main {

	section{

		&#hero{
			.content{
				.container{
					height: 100vh;
					@include flex(column,initial,center);
					padding-top: 140px;
					img{
						max-width: 320px;
					}
					@include tablet-up{
						justify-content: center;
						align-items: flex-end;
						padding-top: 0;
						img{
							max-width: 720px;
						}
					}
				}
			}
		}

		&#music{
			background-color: $white;
			padding: 80px 0;
			.container{
				.split{
					padding-bottom: 30px;
					position: relative;
					gap: 0;
					&::before{
						content: "";
						background-color: $yellow;
						background: rgb(93,72,0);
						background: linear-gradient(90deg, rgba(93,72,0,1) 0%, rgba(212,165,42,1) 100%);
						height: calc(100% - 100px);
						width: 100%;
						bottom: 0;
						left: 0;
						position: absolute;
					}
					.side{
						position: relative;
						p{
							text-transform: uppercase;
							text-align: center;
							font-weight: 700;
							font-size: 20px;
							margin: 0 0 30px;
						}
						.links{
							list-style: none;
							margin: 0;
							padding: 0;
							@include flex(row,center,center);
							gap: 12px;
							a{
								img{
									width: 30px;
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				padding: 120px 0;
				.container{
					.split{
						flex-direction: row-reverse;
						align-items: center;
						padding: 20px 0 0;
						&::before{
							height: 100%;
							width: calc(50% + 200px);
						}
						.side{
							h2{
								margin-bottom: 10px;
							}
							.links{
								li{
									a{
										img{
											width: 50px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&#tour{
			padding: 80px 0;
			.content{
				.container{
					h2{
						margin-bottom: 30px;
					}
					#events{
						display: grid;
						gap: 30px;
						.event{
							text-transform: uppercase;
							display: grid;
							text-align: center;
							gap: 10px;
							justify-items: center;
							p{
								font-size: 18px;
								font-weight: 500;
							}
							.btn{
								width: 310px;
								max-width: 100%;
							}
							&:last-child{
								&::after{
									display: none;
								}
							}
							&::after{
								content: "";
								width: 164px;
								height: 1px;
								background-color: rgba(255, 255, 255, 0.5);
								margin: 30px auto 0;
							}
						}
					}
				}
			}

			@include tablet-up{
				.content{
					.container{
						h2{
							text-align: left;
						}
						#events{
							gap: 40px;
							.event{
								position: relative;
								padding: 20px 0;
								@include flex(row,space-between,center);
								p{
									flex: 1;
									margin: 0;
									text-align: left;
								}
								.btn{
									flex: 1;
									padding: 12px 40px 10px;
									max-width: 180px;
								}
								&::after{
									position: absolute;
									width: 100%;
									bottom: 0;
									left: 0;
								}
							}
						}
					}
				}
			}
		}
		
		&#videos{
			background-color: $white;
			padding: 80px 0;
			.content{
				h2{
					color: $black;
				}
				.wrapper{
					display: grid;
					gap: 40px;
					justify-items: center;
					iframe{
						width: 100%;
						aspect-ratio: 16 / 9;
					}
				}
			}
			@include tablet-up{
				.bg{
					width: 1080px;
				}
				.content{
					.wrapper{
						h2{
							width: 100%;
							text-align: left;
						}
						max-width: 600px;
						margin-left: auto;
					}
				}
			}
		}

		&#about{
			background-color: #000;
			@include tablet-down{
				.bio{
					>.container{
						padding: 0;
						>.wrapper{
							.bg{
								height: auto;
							}
							.content{
								padding-top: 700px;
								padding-bottom: 80px;
								.container{
									h2{
										margin-bottom: 30px;
									}
									p{
										line-height: 1.6;
										margin-bottom: 20px;
										font-size: 16px;
										&:last-child{
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}

				}
			}
			@include tablet-up{
				.bio{
					padding: 160px 0;
					background-color: $black;
					.container{
						.wrapper{
							.content{
								padding: 70px;
								.container{
									max-width: 500px;
									margin: 0;
									h2{
										text-align: left;
										margin-bottom: 30px;
									}
									p{
										font-size: 16px;
										line-height: 1.6;
										margin-bottom: 20px;
										&:last-child{
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}
			}
			.feed{
				background-color: $black;
				padding: 80px 0;
				.container{
					h3{
						color: $yellow;
						text-align: center;
						font-size: 30px;
						margin: 0 0 30px;
					}
				}
				@include tablet-up{
					padding-top: 0;
				}
			}
		}

	}

}

footer {
	padding: 30px;
	.container{
		@include flex(column,center,center);
		gap: 60px;
		.logo{
			width: 52px;
			@include tablet-up{
				width: 32px;
			}
		}
		.social{
			@include tablet-down{
				a{
					img{
						width: 70px;
					}
				}
			}
		}
		.copyright{
			p{
				font-size: 16px;
				text-transform: uppercase;
				line-height: 1.6;
				margin: 0;
				text-align: center;
				a{
					@include hover-focus{
						color: $yellow;
					}
				}
			}
		}
	}
}